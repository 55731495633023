import { h, Component } from 'preact';
import { Link } from 'preact-router/match';

import { Text } from 'preact-i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';

export default class Header extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div class="header-wrapper">
				<header class="header">
					<h1><Text id="header.title">Posts Tracker</Text></h1>
					<nav>
						<Link activeClassName="active" href="/"><Text id="header.homeLinkTitle">Home</Text></Link>
						<Link activeClassName="active" href="/covid19/"><Text id="header.covid19LinkTitle">COVID-19</Text></Link>
						<Link class="searchLink" onClick={this.props.toggleSearchPanel}>
							<span>
								<FontAwesomeIcon icon={faSearch} size={"2x"} />
							</span>
						</Link>
						<Link class="prefsLink" onClick={this.props.togglePrefsPanel}>
							<span>
								<FontAwesomeIcon icon={faCog} size={"2x"} />
							</span>
						</Link>
					</nav>
				</header>
			</div>
		)
	}
};
