import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";

import { Text } from 'preact-i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from '@material-ui/core/Modal';
import Grid from "@material-ui/core/Grid";

import style from './style.css';

export default class Intro extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const [open, setOpen] = useState(true);

		const handleClose = () => {
			setOpen(false);
		};

		return (
			<Modal
				open={open}
				onClose={handleClose}
				className={style.introModal}>
				<div class={style["introModal-container"]}>
					<div class={style["introModal-content"]}>
						<div class={style["introModal-topMetaActions"]}>
							<div class={style["introModal-closeAction"]}>
								<button onClick={handleClose} class={style["introModal-closeButton"]}>
									<FontAwesomeIcon icon={faTimes} size={"2x"} />
								</button>
							</div>
						</div>
						<img src="/assets/img/logo_1.jpg" alt="" class={style["introModal-logo"]} />
						<h2><Text id="intro.title">Fatabyyano Posts Tracker</Text></h2>

						<p><Text id="intro.description"></Text></p>

						<h3><Text id="intro.howToUse">How To Use</Text></h3>
						<div class={style["introModal-usageMethods"]}>
							<Grid
								container
								direction="row"
								justify="space-around"
								alignItems="center">
									<div class={style["introModal-usageMethod"]}>
										<div class={style["introModal-usageMethodIcon"]}>
											<img src="/assets/img/icon_1.png" alt="" />
										</div>
										<h4><Text id="intro.usageMethodKeyboard">Keyboard Arrows Keys</Text></h4>
									</div>
									<div class={style["introModal-usageMethod"]}>
										<div class={style["introModal-usageMethodIcon"]}>
											<img src="/assets/img/icon_2.png" alt="" />
										</div>
										<h4><Text id="intro.usageMethodMouse">Mouse Scroll Wheel</Text></h4>
									</div>
									<div class={style["introModal-usageMethod"]}>
										<div class={style["introModal-usageMethodIcon"]}>
											<img src="/assets/img/icon_3.png" alt="" />
										</div>
										<h4><Text id="intro.usageMethodScrollbar">Site Scrollbar</Text></h4>
									</div>
							</Grid>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
};
