import { h, Component } from 'preact';
import { Router } from 'preact-router';

import Intro from './intro';
import Header from './header';

import Home from '../routes/home';
import Theatre from '../routes/theatre';

import { IntlProvider } from 'preact-i18n';
import definition from '../locales/ar.json';

import config from "../../config";

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPrefsPanel: false,
			showSearchPanel: false,
		};
		this.togglePrefsPanel = this.togglePrefsPanel.bind(this);
		this.toggleSearchPanel = this.toggleSearchPanel.bind(this);
	}

	/** 
	 * Gets fired when the route changes.
	 * 
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	}

	togglePrefsPanel = () => {
		this.setState({
			showPrefsPanel: !this.state.showPrefsPanel
		});
	}

	toggleSearchPanel = () => {
		this.setState({
			showSearchPanel: !this.state.showSearchPanel
		});
	}

	render() {
		return (
			<IntlProvider definition={definition}>
				<div id="app">
					<Header
						togglePrefsPanel={this.togglePrefsPanel}
						toggleSearchPanel={this.toggleSearchPanel}
					/>
					<Router onChange={this.handleRoute}>
						<Home
							path="/"
							theatreName="home"
							togglePrefsPanel={this.togglePrefsPanel}
							showPrefsPanel={this.state.showPrefsPanel}
							toggleSearchPanel={this.toggleSearchPanel}
							showSearchPanel={this.state.showSearchPanel}
							currentTags={[]}
						/>
						<Theatre
							path="/covid19/"
							theatreName="covid19"
							togglePrefsPanel={this.togglePrefsPanel}
							showPrefsPanel={this.state.showPrefsPanel}
							toggleSearchPanel={this.toggleSearchPanel}
							showSearchPanel={this.state.showSearchPanel}
							currentTags={config.pageCovid19Tags || []}
						/>
					</Router>
				</div>
				<Intro />
			</IntlProvider>
		);
	}
}
